import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"

const Blog = props => {
  const { blog } = props

  return (
    <div className="description card">
      {blog.map((registration, index) => {
        return (
          <div key={index} className="course-tile-card">
            <a target="_blank" href={registration.blog_link}>
              <div className="course-tile-img">
                <img src={registration.image} />
              </div>
            </a>

            <div>
              <h4>
                <strong style={{ marginRight: "20px" }}>
                  {registration.display_name}
                </strong>
              </h4>

              {registration.session_details &&
                registration.session_details.length > 0 && (
                  <div className="ml-15 bold">
                    {registration.session_details}
                  </div>
                )}

              <h6>
                {registration.blog_link != null && (
                  <a target="_blank" href={registration.blog_link} className>
                    Read Now
                  </a>
                )}
              </h6>
            </div>
          </div>
        )
      })}
    </div>
  )
}

const BlogComponent = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            Blog {
              display_name
              image
              blog_link
              session_details
            }
          }
        }
      }
    `
  )

  return (
    <div>
      <Tabs>
        <TabList>
          <Tab>
            <h5>Blog</h5>
          </Tab>
        </TabList>
        <TabPanel>
          <Blog blog={site.siteMetadata.Blog} />
        </TabPanel>
      </Tabs>
    </div>
  )
}

export default BlogComponent
